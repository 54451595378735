import React from "react";
import {Grid} from "@mui/material";
import {useTheme} from "@mui/system";
import Text from "../../components/data-display/text";
import {FaStar} from "react-icons/fa";
import {environment} from "../../environments/quickTaskEnvironemt"; // Importing the environment object
import syde_tasks from "../../components/home/task-examples/featured-tasks.json";
import Quick_tasks from "../../components/home/task-examples/QuickTask_featured-tasks.json";
import {isQuickTaskProject, title} from "../../utils/constant.urls";
const QuickTaskMainColor = environment.mainColor;
const QuickTaskThirdColor = environment.thirdColor;
const QT_FirstTaskerImg =
  "https://firebasestorage.googleapis.com/v0/b/sydework-dev.appspot.com/o/quickTask_imgs%2FMan%20with%20back%20ground.png?alt=media&token=75302a08-8119-4fdb-8cd1-19067d8ef9d4";
const QT_secondTaskerImg =
  "https://firebasestorage.googleapis.com/v0/b/sydework-dev.appspot.com/o/quickTask_imgs%2FFemale%20worker.png?alt=media&token=c7200f6c-1059-4acd-ba49-a0434542ed58";
const QT_thirdTaskerImg =
  "https://firebasestorage.googleapis.com/v0/b/sydework-dev.appspot.com/o/quickTask_imgs%2FDry%20waller.png?alt=media&token=2c25bda3-a0ee-4901-90d9-0abf566bc761";
const ST_FirstTaskerImg =
  "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/taskers%2Fturner.webp?alt=media&token=69a8b8dd-9bbe-47c1-8491-28d9f4f60905";
const ST_SecondTaskerImg =
  "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/taskers%2Fali.webp?alt=media&token=5ed70a53-c069-4094-875b-9aa566f151bf";
const ST_ThirdTaskerImg =
  "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/taskers%2Frocky.webp?alt=media&token=baa3ab9d-9378-46b3-8d05-24ae9a18ba96";
const first_img = isQuickTaskProject ? QT_FirstTaskerImg : ST_FirstTaskerImg;
const second_img = isQuickTaskProject ? QT_secondTaskerImg : ST_SecondTaskerImg;
const third_img = isQuickTaskProject ? QT_thirdTaskerImg : ST_ThirdTaskerImg;
const city = isQuickTaskProject ? "London" : "Milwaukee";

export default function FeaturedTaskersSection() {
  const theme = useTheme();

  const [tasker, setTasker] = React.useState(0);
  const palette_Color = isQuickTaskProject ? QuickTaskMainColor : theme.palette.primary.main;

  return (
    <div>
      {" "}
      <div
        style={{
          position: "relative",
          zIndex: 1,
          transform: "translateY(-4px)",
          padding: "48px 0",
          background: "#F1FEF4",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          display: "flex",
        }}
      >
        <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
          <Text variant={"h2"} black>
            Featured Taskers
          </Text>
          <div style={{height: "12px"}} />
          <div style={{display: "flex"}}>
            <div
              style={{cursor: "pointer"}}
              onClick={() => {
                setTasker(0);
              }}
            >
              <Text
                variant={"h6"}
                bold
                style={{color: tasker === 0 ? palette_Color : "grey", margin: "0 8px"}}
              >
                Turner
              </Text>
            </div>
            <div
              style={{cursor: "pointer"}}
              onClick={() => {
                setTasker(1);
              }}
            >
              <Text
                variant={"h6"}
                bold
                style={{color: tasker === 1 ? palette_Color : "grey", margin: "0 8px"}}
              >
                Ali
              </Text>
            </div>
            <div
              style={{cursor: "pointer"}}
              onClick={() => {
                setTasker(2);
              }}
            >
              <Text
                variant={"h6"}
                bold
                style={{color: tasker === 2 ? palette_Color : "grey", margin: "0 8px"}}
              >
                Rocky
              </Text>
            </div>
          </div>
          <div style={{height: "12px"}} />
        </div>
        <div style={{margin: "16px 0px", maxWidth: "960px"}}>
          <div style={{overflow: "hidden"}}>
            <Grid container direction="row" justifyContent="center">
              <Grid item xl={5} lg={4} md={4} sm={4} xs={12}>
                <div style={{position: "relative"}}>
                  <div style={{height: "550px", overflow: "hidden"}}>
                    {tasker === 0 ? (
                      <img
                        loading="lazy"
                        style={{width: "100%"}}
                        src={first_img}
                        alt={`Turner - Featured Tasker on ${title}`}
                      />
                    ) : (
                      ""
                    )}
                    {tasker === 1 ? (
                      <img
                        loading="lazy"
                        style={{width: "100%"}}
                        src={third_img}
                        alt={`Ali - Featured Tasker on ${title}`}
                      />
                    ) : (
                      ""
                    )}
                    {tasker === 2 ? (
                      <img
                        loading="lazy"
                        style={{width: "100%"}}
                        src={second_img}
                        alt={`Rocky - Featured Tasker on ${title}`}
                      />
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        position: "absolute",
                        left: "24px",
                        top: "24px",
                        background: "white",
                        border: "1.5px solid rgba(0,0,0,0.1)",
                        padding: "8px 16px",
                        borderRadius: "59px",
                        marginRight: "12px",
                        marginBottom: "12px",
                      }}
                    >
                      <div style={{display: "flex"}}>
                        {[0, 1, 2, 3, 4].map((i) => {
                          return (
                            <FaStar
                              key={i}
                              color={"rgb(255, 186, 0)"}
                              style={{width: `${20}px`, height: `${20}px`, marginRight: "3px"}}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xl={7} lg={7} md={7} sm={8} xs={12}>
                <div style={{padding: "0 32px"}}>
                  <div style={{height: "18px"}} />
                  <div style={{display: "flex"}}>
                    <Text bold variant={"h6"} style={{}}>
                      Hi! I'm {tasker === 0 && "Turner"} {tasker === 1 && "Ali"}{" "}
                      {tasker === 2 && "Rocky"}
                      <span style={{marginLeft: "12px"}} />
                      👋
                    </Text>
                  </div>
                  <div style={{height: "18px"}} />
                  <Text regular variant={"h6"} style={{width: "88%"}}>
                    {tasker === 0
                      ? `By day, Turner is busy working & learning how to be an electrician. However, he enjoys spending some of his free afternoons doing side jobs on ${title} for a supplemental income. He has been a Tasker since 2019. `
                      : ""}
                    {tasker === 1
                      ? `Ali has a full-time job in ${city} working for a non-profit program called “Respect Our Waters”. When she isn’t helping our environment, she is helping others get more done on ${title}.`
                      : ""}
                    {tasker === 2
                      ? `As an independent business owner, Rocky is the ultimate handyman who picks up extra jobs on ${title}. He has his own insurance, can complete tasks from a full bathroom remodel to a single door knob fix, & has been a Tasker since early 2020. \n`
                      : ""}
                  </Text>
                  <div style={{height: "18px"}} />
                  <Text variant={"overline"}>SKILLS</Text>
                  <div style={{height: "6px"}} />
                  <div style={{display: "flex", flexWrap: "wrap", width: "80%"}}>
                    {tasker === 0 &&
                      ["Furniture Assembly", "Heavy Lifting", "General Yardwork"].map((s) => {
                        return (
                          <div
                            key={s}
                            style={{
                              background: "teal",
                              border: "1.5px solid rgba(0,0,0,0.1)",
                              padding: "4px 16px",
                              borderRadius: "59px",
                              marginRight: "12px",
                              marginBottom: "12px",
                            }}
                          >
                            <Text medium variant={"body2"} style={{color: "white"}}>
                              {s}
                            </Text>
                          </div>
                        );
                      })}
                    {tasker === 1 &&
                      ["Yardwork", "House Cleaning", "Pet Sitting", "Administrative Tasks"].map(
                        (s) => {
                          return (
                            <div
                              style={{
                                background: "teal",
                                border: "1.5px solid rgba(0,0,0,0.1)",
                                padding: "4px 16px",
                                borderRadius: "59px",
                                marginRight: "12px",
                                marginBottom: "12px",
                              }}
                            >
                              <Text medium variant={"body2"} style={{color: "white"}}>
                                {s}
                              </Text>
                            </div>
                          );
                        }
                      )}
                    {tasker === 2 &&
                      ["Handyman", "Carpentry", "Construction, Hauling"].map((s) => {
                        return (
                          <div
                            style={{
                              background: "teal",
                              border: "1.5px solid rgba(0,0,0,0.1)",
                              padding: "4px 16px",
                              borderRadius: "59px",
                              marginRight: "12px",
                              marginBottom: "12px",
                            }}
                          >
                            <Text medium variant={"body2"} style={{color: "white"}}>
                              {s}
                            </Text>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}
