import * as React from "react";
import {Grid, Typography} from "@mui/material";
import {useTheme} from "@mui/system";
import {useMediaQuery} from "@mui/material";
import {environment} from "../../environments/quickTaskEnvironemt";
import {isQuickTaskProject} from "../../utils/constant.urls";
import DialogBottomButton from "../buttons/dialog-bottom-button";
import {auth} from "../../utils/firebase.utils";

let data = [
  {
    number: 1,
    title: "Showcase Your Skill",
    description:
      "Highlight and share your expertise by posting your skills on QuickTask. Connect with those seeking your talents and turn your passion into opportunities.",
  },
  {
    number: 2,
    title: "Stay connected with task posters",
    description:
      "Effortlessly stay in touch with task posters on QuickTask. Seamless communication ensures you're always in the loop and ready to tackle the next task.",
  },
  {
    number: 3,
    title: "Accept Task Requests",
    description:
      "Effortlessly accept tasks on QuickTask. Be proactive andresponsive, ensuring a smooth and efficient experience for bothyou and the task posters.",
  },
];
export default function QuickTaskerStepsSection() {
  const theme = useTheme();
  const currentUser = auth?.currentUser;
  const QuickTaskMainColor = environment.mainColor;
  const palette_Color = isQuickTaskProject ? QuickTaskMainColor : theme.palette.primary.main;
  const xsDown = useMediaQuery(theme.breakpoints.down("sm"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <div
        style={{
          maxWidth: "900px",
          marginTop: "100px",
          ...(mdDown ? {margin: "50px"} : {margin: "48px"}),
        }}
      >
        <Grid container direction="row" justifyContent={"space-between"} spacing={xsDown ? 2 : 4}>
          <Typography
            variant="h6"
            style={{
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "12px",
              letterSpacing: "2px",
              marginBottom: "10px",
            }}
          >
            HOW DOES IT WORK FOR TASKERS?
          </Typography>
          <Typography
            variant="h5"
            style={{
              //textTransform: "uppercase",
              fontWeight: "bold",
              //fontSize: "12px",
              //letterSpacing: "2px",
              marginBottom: "30px",
            }}
          >
            Ready to transform your knowledge, talent, or hobby into income?
          </Typography>
          <Grid container>
            {data.map((item, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={index}
                style={{marginBottom: "30px", padding: "10px"}}
              >
                <Grid container alignItems="center" key={index} spacing={3}>
                  <Grid item xs={2}>
                    <Typography variant="h2" style={{color: palette_Color, fontWeight: "bold"}}>
                      {item.number}
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="h6">{item.title}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">{item.description}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>{" "}
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: smDown ? "15px" : "60px",
          }}
        >
          {" "}
          <DialogBottomButton
            to={currentUser ? "/my-tasks" : "/login"}
            showBackgroundFade={false}
            backgroundColor={palette_Color}
            textColor={"white"}
            style={{
              maxWidth: "320px",
              left: "auto",
              zIndex: 300,
              marginTop: "35px",
              padding: 0,
              position: "absolute",
              minHeight: "15vh",
              minWidth: "20vh",
            }}
          >
            Become A Tasker
          </DialogBottomButton>
        </div>
      </div>
    </div>
  );
}
