import * as React from "react";
import {Grid} from "@mui/material";
import {useTheme} from "@mui/system";
import {useMediaQuery} from "@mui/material";
import Text from "../data-display/text";
import Sy_step3 from "../../images/icon_3.png";
import {environment} from "../../environments/quickTaskEnvironemt";
import {isQuickTaskProject} from "../../utils/constant.urls";
export default function StepsSection() {
  const theme = useTheme();
  const QuickTaskMainColor = environment.mainColor;
  const QuickTaskThirdColor = environment.thirdColor;
  const firstColor = isQuickTaskProject ? QuickTaskMainColor : "#6333ff";
  const secondColor = isQuickTaskProject ? QuickTaskThirdColor : "#441ebf";
  const xsDown = useMediaQuery(theme.breakpoints.down("sm"));
  const Sy_step1 =
    "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/images%2Ficon_1.webp?alt=media&token=daa3a1cd-b39e-4fc1-9dae-0f11814b3fda";
  const Sy_step2 =
    "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/images%2Ficon_2.webp?alt=media&token=85263787-a6f3-4c79-95c0-dc0589ec6542";
  const Sy_step4 =
    "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/images%2Ficon_4.png?alt=media&token=6d60a209-5832-4207-a427-b3acec26ac8a";
  const QT_step1 =
    "https://firebasestorage.googleapis.com/v0/b/sydework-dev.appspot.com/o/quickTaskSteps%2FTyping-bro.svg?alt=media&token=e65e8f1f-4f0b-4722-95d8-40951a3daad4";
  const QT_step2 =
    "https://firebasestorage.googleapis.com/v0/b/sydework-dev.appspot.com/o/quickTaskSteps%2FReview.svg?alt=media&token=6a6daf99-b418-4115-adab-ee2cf0d92b15";
  const QT_step3 =
    "https://firebasestorage.googleapis.com/v0/b/sydework-dev.appspot.com/o/quickTaskSteps%2FPayment%20Information-bro.svg?alt=media&token=b784d161-ad7a-4857-8148-f5dd4c55b824";
  const QT_step4 =
    "https://firebasestorage.googleapis.com/v0/b/sydework-dev.appspot.com/o/quickTaskSteps%2FDone-rafiki.svg?alt=media&token=4bcac9b7-2440-4a5d-aa7f-353962c10b5d";
  const step1 = isQuickTaskProject ? QT_step1 : Sy_step1;
  const step2 = isQuickTaskProject ? QT_step2 : Sy_step2;
  const step3 = isQuickTaskProject ? QT_step3 : Sy_step3;
  const step4 = isQuickTaskProject ? QT_step4 : Sy_step4;

  return (
    <div>
      {" "}
      <div style={{margin: xsDown ? "18px" : "48px 18px", maxWidth: "900px"}}>
        <Grid container direction="row" justifyContent={"space-between"} spacing={xsDown ? 2 : 4}>
          <Grid item md={6} sm={6} xs={11}>
            <div
              style={{
                background: xsDown
                  ? "linear-gradient(rgb(245, 244, 247), rgba(247, 248, 251, 0))"
                  : "",
                borderRadius: "12px",
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent={xsDown ? "space-between" : "flex-start"}
                alignItems={"flex-start"}
                style={{padding: "16px 10px"}}
              >
                <Grid item xs={4} md={4}>
                  <img
                    loading={"lazy"}
                    alt={"Post a Task"}
                    src={step1}
                    height={xsDown ? "80px" : "100px"}
                  />
                </Grid>
                <Grid item xs={7} md={6}>
                  <Grid container direction="column">
                    <Grid item md={12}>
                      <div style={{display: "flex"}}>
                        {!xsDown && (
                          <div
                            style={{
                              width: "22px",
                              height: "22px",
                              position: "absolute",
                              background: `linear-gradient(181.81deg, ${firstColor} 1.51%, ${secondColor} 98.47%)`,
                              transform: "translateX(-28px)",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Text
                              variant={"caption"}
                              component={"p"}
                              style={{color: "white", transform: "translateX(-0.5px)"}}
                              bold
                            >
                              1
                            </Text>
                          </div>
                        )}
                        <Text style={{marginBottom: "8px"}} variant={"body2"} bold component={"p"}>
                          Post a Task
                        </Text>
                      </div>
                    </Grid>
                    <Grid item md={12}>
                      <Text
                        style={{width: xsDown ? "100%" : "80%", marginBottom: "60px"}}
                        variant={"body2"}
                        component={"p"}
                        regular
                      >
                        Describe your task & post it. (It's free)
                      </Text>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item md={6} sm={6} xs={11}>
            <div style={{borderRadius: "12px"}}>
              <Grid
                container
                direction="row"
                justifyContent={xsDown ? "space-between" : "flex-start"}
                alignItems="flex-start"
                style={{padding: "16px 10px"}}
              >
                <Grid item xs={4} md={4}>
                  <img
                    loading={"lazy"}
                    alt={"Review Offers"}
                    src={step2}
                    height={xsDown ? "80px" : "100px"}
                  />
                </Grid>
                <Grid item xs={7} md={6}>
                  <Grid container direction="column">
                    <Grid item md={12}>
                      <div style={{display: "flex"}}>
                        {!xsDown && (
                          <div
                            style={{
                              width: "22px",
                              height: "22px",
                              position: "absolute",
                              background: `linear-gradient(181.81deg, ${firstColor} 1.51%, ${secondColor} 98.47%)`,
                              transform: "translateX(-28px)",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Text
                              variant={"caption"}
                              component={"p"}
                              style={{color: "white", transform: "translateX(-0.5px)"}}
                              bold
                            >
                              2
                            </Text>
                          </div>
                        )}
                        <Text style={{marginBottom: "8px"}} variant={"body2"} bold component={"p"}>
                          Review Offers
                        </Text>
                      </div>
                    </Grid>
                    <Grid item md={12}>
                      <Text style={{marginBottom: "60px"}} variant={"body2"} regular>
                        View offers from local Taskers and accept your top choice.
                      </Text>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item md={6} sm={6} xs={11}>
            <div style={{borderRadius: "12px"}}>
              <Grid
                container
                direction="row"
                justifyContent={xsDown ? "space-between" : "flex-start"}
                alignItems={"flex-start"}
                style={{padding: "16px 10px"}}
              >
                <Grid item xs={4} md={4}>
                  <img
                    loading={"lazy"}
                    alt={"Easy Payment"}
                    src={step3}
                    height={xsDown ? "80px" : "100px"}
                  />
                </Grid>
                <Grid item xs={7} md={6}>
                  <Grid container direction="column">
                    <Grid item md={12}>
                      <div style={{display: "flex"}}>
                        {!xsDown && (
                          <div
                            style={{
                              width: "22px",
                              height: "22px",
                              position: "absolute",
                              background: `linear-gradient(181.81deg, ${firstColor} 1.51%, ${secondColor} 98.47%)`,
                              transform: "translateX(-28px)",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Text
                              variant={"caption"}
                              component={"p"}
                              style={{color: "white", transform: "translateX(-0.5px)"}}
                              bold
                            >
                              3
                            </Text>
                          </div>
                        )}
                        <Text style={{marginBottom: "8px"}} variant={"body2"} bold component={"p"}>
                          Easy Payment
                        </Text>
                      </div>
                    </Grid>
                    <Grid item md={12}>
                      <Text style={{marginBottom: "60px"}} variant={"body2"} regular>
                        Payment can be hourly or flat rate, secure & simple.
                      </Text>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item md={6} sm={6} xs={11}>
            <div style={{borderRadius: "12px"}}>
              <Grid
                container
                direction="row"
                justifyContent={xsDown ? "space-between" : "flex-start"}
                alignItems="flex-start"
                style={{padding: "16px 10px"}}
              >
                <Grid item xs={4} md={4}>
                  <img
                    loading={"lazy"}
                    alt={"Complete Task"}
                    src={step4}
                    height={xsDown ? "80px" : "100px"}
                  />
                </Grid>
                <Grid item xs={7} md={6}>
                  <Grid container direction="column">
                    <Grid item md={12}>
                      <div style={{display: "flex"}}>
                        {!xsDown && (
                          <div
                            style={{
                              width: "22px",
                              height: "22px",
                              position: "absolute",
                              background: `linear-gradient(181.81deg, ${firstColor} 1.51%, ${secondColor} 98.47%)`,
                              transform: "translateX(-28px)",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Text
                              variant={"caption"}
                              component={"p"}
                              style={{color: "white", transform: "translateX(-0.5px)"}}
                              bold
                            >
                              4
                            </Text>
                          </div>
                        )}
                        <Text style={{marginBottom: "8px"}} variant={"body2"} bold component={"p"}>
                          Complete task
                        </Text>
                      </div>
                    </Grid>
                    <Grid item md={12}>
                      <Text
                        style={{marginBottom: "60px"}}
                        variant={"body2"}
                        component={"p"}
                        regular
                      >
                        Discuss details with your tasker & get it done.
                      </Text>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
