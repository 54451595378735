import * as React from "react";
import SEO from "../components/seo/seo";
import {Grid} from "@mui/material";
import {useTheme} from "@mui/system";
import {useMediaQuery} from "@mui/material";
import Text from "../components/data-display/text";
import ThumbnailImage from "../images/thumbnail.webp";
import SydetaskerVideo from "../images/sydetasker.mp4";
import ReactPlayer from "react-player";
import sydePhone from "../images/iphone.jpg";
import quickPhone from "../quickTaskAssets/mobilescreen.webp";
import {Play} from "react-feather";
import {environment} from "../environments/quickTaskEnvironemt";
import QuickTaskVideo from "../quickTaskAssets/Video/QuickTask_Home_Page_Video.mp4";
import ThumbnailImage5 from "../quickTaskAssets/Video/QuickTaskHomePageVideo.webp";
import FeaturedTaskersSection from "../components/home/FeaturedTaskersSection";
import {descriptionInfo, isQuickTaskProject, title, yardWork} from "../utils/constant.urls";
import StepsSection from "../components/home/StepsSection";
import QuickTaskerStepsSection from "../components/home/QuickTaskerStepsSection";
import AllBlogsSection from "./../components/home/AllBlogsSection";
import AllReviewSection from "./../components/home/AllReviewSection";
import AllCategoriesSection from "../components/home/AllCategoriesSection";
import AllStaticTasks from "./../components/home/AllStaticTasks";
import LandingPageSection from "./../components/home/LandingPageSection";
import QTAllCategoriesSection from "../components/home/QTAllCategoriesSection";
const LandingPage = () => {
  const theme = useTheme();
  const QuickTaskMainColor = environment.mainColor;
  const palette_Color = isQuickTaskProject ? QuickTaskMainColor : theme.palette.primary.main;
  const SeoImage = isQuickTaskProject ? quickPhone : sydePhone;

  return (
    <>
      <SEO
        title={`${title} - ${descriptionInfo}`}
        pathname={"/"}
        image={{src: SeoImage}}
        description={`Any task you need done? ${title}  easily connects you with trusted & experienced Taskers ready to help with home repairs, heavy lifting, ${yardWork}, & more.`}
      />
      <LandingPageSection />
      {isQuickTaskProject ? <QTAllCategoriesSection /> : <AllCategoriesSection />}

      {/* <div style={{ position: "relative", zIndex: 1, padding: "48px 0", alignItems: "center", justifyContent: "center", flexDirection: "column", display: "flex" }}>
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
          <Text variant={"h3"} black>See what others are getting done</Text>
          <div style={{ height: "48px" }} />
        </div>
          <Box style={{ width:"100%" }}>
          <Box style={{ display:"flex", justifyContent:"center"}}>
            <Tabs     
            classes={{
              indicator: classes.indicator
            }}
            value={value}onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Cleaning & Organizing" {...a11yProps(0)} />
              <Tab label="Extra Hands" {...a11yProps(1)} />
              <Tab label="Home Improvement" {...a11yProps(2)} />
              <Tab label="Seasonal Jobs" {...a11yProps(3)} />
              <Tab label="Anything" {...a11yProps(4)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}style={{ width:"100%" }}>
              <ScrollingTasks tasks={tasks.data.slice(0, 6)} reverse />
              <ScrollingTasks tasks={tasks.data.slice(6 ,12)} />
          </TabPanel>
          <TabPanel value={value} index={1}>
              <ScrollingTasks tasks={tasks.data.slice(12,18 )} reverse />
              <ScrollingTasks tasks={tasks.data.slice(18 ,24)} />
          </TabPanel>
          <TabPanel value={value} index={2}>
             <ScrollingTasks tasks={tasks.data.slice(24,30 )} reverse />
              <ScrollingTasks tasks={tasks.data.slice(30 ,36)} />
          </TabPanel>
          <TabPanel value={value} index={3}>
              <ScrollingTasks tasks={tasks.data.slice(36,42 )} reverse />
              <ScrollingTasks tasks={tasks.data.slice(42 ,48)} />
          </TabPanel>
          <TabPanel value={value} index={4}>
              <ScrollingTasks tasks={tasks.data.slice(48,54)} reverse />
              <ScrollingTasks tasks={tasks.data.slice(54,60)} />
          </TabPanel>
        </Box>
        */}
      {/* <div style={{ width: "200px", marginTop: "24px" }}>
          <SecondaryButton to={"/login"} background={palette_Color} textColor={'white'}>
            Post a Task
          </SecondaryButton>
        </div> */}
      {/* </div> */}
      <AllStaticTasks />
      <div
        style={{
          position: "relative",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          display: "flex",
          paddingTop: "64px",
        }}
      >
        <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
          <Text variant={"h2"} black>
            How {title} Works
          </Text>
          <div style={{height: "12px"}} />
          <Text
            variant={"body1"}
            style={{maxWidth: "550px", margin: "0 auto", textAlign: "center"}}
          >
            Watch the video below to understand how {title} can help get your tasks done easily.
          </Text>
        </div>
        <div style={{height: "32px"}} />

        {
          <Grid container direction="row" justifyContent={"center"}>
            <Grid
              item
              md={6}
              sm={6}
              xs={11}
              style={{display: "flex", alignItems: "center", justifyContent: "center"}}
            >
              <ReactPlayer
                url={isQuickTaskProject ? QuickTaskVideo : SydetaskerVideo}
                playIcon={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "100%",
                      background: "rgba(0,0,0,0.2)",
                    }}
                  >
                    <div
                      style={{
                        background: palette_Color,
                        borderRadius: "50%",
                        width: "64px",
                        height: "64px",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <Play
                        size={28}
                        style={{transform: "translateX(2px)", width: "28px"}}
                        color="white"
                      />
                    </div>
                  </div>
                }
                light={isQuickTaskProject ? ThumbnailImage5 : ThumbnailImage}
                controls={true}
                playing={true}
              />
            </Grid>
          </Grid>
        }
        <StepsSection />
        {isQuickTaskProject && <QuickTaskerStepsSection />}
      </div>
      <div style={{width: "100%", height: "100%"}}>
        <img
          loading="lazy"
          src={
            "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/images%2Fwave_3.webp?alt=media&token=124593b7-46fe-47e2-ae96-62b0f73018db"
          }
          width={"100%"}
          alt={"background"}
        />
      </div>
      <FeaturedTaskersSection />
      <AllBlogsSection />
      <AllReviewSection />
      {/*<DialogWrapper maxWidth={'md'} height={'unset'} root onClose={() => { setVideo(false) }} open={video}>*/}
      {/*  <ReactPlayer*/}
      {/*      url={SydetaskerVideo}*/}
      {/*      style={{ width: "100%", padding: "12px 0" }}*/}
      {/*      className='react-player'*/}
      {/*      // playIcon={<div style={{ background: palette_Color, borderRadius: "50%", width: '64px', height: '64px', alignItems: "center", justifyContent: "center", display: "flex" }}><Play size={28} style={{ transform: "translateX(2px)", width: "28px" }} color='white' /></div>}*/}
      {/*      // light={ThumbnailImage}*/}
      {/*      controls={true} playing={true}*/}
      {/*  />*/}
      {/*</DialogWrapper>*/}
    </>
  );
};
// const App = ({transitionStatus})=>{
//   const location =useLocation();

//   return(
//     <div>
//       <div>
//         <Router location={location}>
//         <NoAuthRoute path="/" component={LandingPage} transitionStatus={transitionStatus} redirect={true}/>

//         </Router>
//         </div>
//     </div>
//   )


// }

export default LandingPage;
