import * as React from "react";
import {Box, Tab, Tabs, Typography} from "@mui/material";
import {useTheme} from "@mui/system";
import {useMediaQuery} from "@mui/material";
import Text from "../../components/data-display/text";
import SecondaryButton from "../../components/buttons/secondary-button";
import syde_tasks from "../../components/home/task-examples/featured-tasks.json";
import Quick_tasks from "../../components/home/task-examples/QuickTask_featured-tasks.json";
import PostTaskWrapper from "../../components/post-task/post-task-wrapper";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/index.reducers";
import {environment} from "../../environments/quickTaskEnvironemt";
import {isQuickTaskProject, title} from "../../utils/constant.urls";
import ScrollingTasks from "./task-examples/scrolling-tasks";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const QuickTaskMainColor = environment.mainColor;
const tasks = isQuickTaskProject ? Quick_tasks : syde_tasks;
const styles = {
  indicator: {
    backgroundColor: "transparent  ",
  },
};
export default function AllStaticTasks() {
  const theme = useTheme();
  const palette_Color = isQuickTaskProject ? QuickTaskMainColor : theme.palette.primary.main;
  const xsDown = useMediaQuery(theme.breakpoints.down("sm"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const {isAuthenticated} = useSelector((state: RootState) => state.userData);
  const OrganizingWord = isQuickTaskProject ? "organising" : "Organizing";
  function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{p: 3}}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      {" "}
      <div
        style={{
          position: "relative",
          zIndex: 1,
          padding: "48px 0",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          display: "flex",
        }}
      >
        <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
          <Text variant={xsDown ? "h4" : "h2"} black>
            Get Anything Done on {title}
          </Text>
          <div style={{height: "48px"}} />
        </div>
        {xsDown ? (
          <Box style={{width: "100%"}}>
            <div style={{display: "flex", alignItems: "center", width: "100%"}}>
              <div style={{overflowX: "auto", WebkitOverflowScrolling: "touch", width: "100%"}}>
                <div style={{display: "-webkit-box", alignItems: "center"}}>
                  <Tabs
                    style={{...(styles.indicator as React.CSSProperties)}}
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label={`Cleaning &${OrganizingWord}`} {...a11yProps(0)} />
                    <Tab label="Extra Hands" {...a11yProps(1)} />
                    <Tab label="Home Improvement" {...a11yProps(2)} />
                  </Tabs>
                  <Tabs
                    style={{...(styles.indicator as React.CSSProperties)}}
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Seasonal Jobs" {...a11yProps(3)} />
                    <Tab label="Anything" {...a11yProps(4)} />
                  </Tabs>
                </div>
              </div>
            </div>
            <TabPanel value={value} index={0} style={{width: "100%"}}>
              <ScrollingTasks tasks={tasks.data.slice(0, 6)} reverse />
              <ScrollingTasks tasks={tasks.data.slice(6, 12)} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ScrollingTasks tasks={tasks.data.slice(12, 18)} reverse />
              <ScrollingTasks tasks={tasks.data.slice(18, 24)} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ScrollingTasks tasks={tasks.data.slice(24, 30)} reverse />
              <ScrollingTasks tasks={tasks.data.slice(30, 36)} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <ScrollingTasks tasks={tasks.data.slice(36, 42)} reverse />
              <ScrollingTasks tasks={tasks.data.slice(42, 48)} />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <ScrollingTasks tasks={tasks.data.slice(48, 54)} reverse />
              <ScrollingTasks tasks={tasks.data.slice(54, 60)} />
            </TabPanel>
          </Box>
        ) : (
          <Box style={{width: "100%"}}>
            <Box style={{display: "flex", justifyContent: "center"}}>
              <Tabs
                style={{...(styles.indicator as React.CSSProperties)}}
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label={`Cleaning &${OrganizingWord}`} {...a11yProps(0)} />
                <Tab label="Extra Hands" {...a11yProps(1)} />
                <Tab label="Home Improvement" {...a11yProps(2)} />
                <Tab label="Seasonal Jobs" {...a11yProps(3)} />
                <Tab label="Anything" {...a11yProps(4)} style={{paddingRight: "40px"}} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0} style={{width: "100%"}}>
              <ScrollingTasks tasks={tasks.data.slice(0, 6)} reverse />
              <ScrollingTasks tasks={tasks.data.slice(6, 12)} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ScrollingTasks tasks={tasks.data.slice(12, 18)} reverse />
              <ScrollingTasks tasks={tasks.data.slice(18, 24)} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ScrollingTasks tasks={tasks.data.slice(24, 30)} reverse />
              <ScrollingTasks tasks={tasks.data.slice(30, 36)} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <ScrollingTasks tasks={tasks.data.slice(36, 42)} reverse />
              <ScrollingTasks tasks={tasks.data.slice(42, 48)} />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <ScrollingTasks tasks={tasks.data.slice(48, 54)} reverse />
              <ScrollingTasks tasks={tasks.data.slice(54, 60)} />
            </TabPanel>
          </Box>
        )}

        {isAuthenticated && (
          <PostTaskWrapper>
            <div style={{width: "200px", marginTop: "24px"}}>
              <SecondaryButton background={theme.palette.primary.main} textColor={"white"}>
                Post a Task
              </SecondaryButton>
            </div>
          </PostTaskWrapper>
        )}

        {!isAuthenticated && (
          <PostTaskWrapper>
            <div style={{width: "200px", marginTop: "24px"}}>
              <SecondaryButton to="/login" background={palette_Color} textColor={"white"}>
                Post a Task
              </SecondaryButton>
            </div>
          </PostTaskWrapper>
        )}
      </div>
    </div>
  );
}
