import * as React from "react";
import { Container, Grid } from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Text from "../data-display/text";
import makeStyles from "@material-ui/core/styles/makeStyles";
import InlineLink from "../data-display/inline-link";
import { getCategoryLink } from "../../content/category-icons";
import { Link } from "gatsby";
import { categories } from "../../utils/category-icons";
import { getCategoryImage } from "../../utils/category-icons";
import { environment } from "../../environments/quickTaskEnvironemt";
import { isQuickTaskProject } from "../../utils/constant.urls";

const QuikTaskMainColor = environment.mainColor;

const useStyles = makeStyles((theme) => ({
  categoryCardContainer: {
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: "8px",
    transition: "0.5s",
    marginTop: "50px",
    "&:hover": {
      filter: "brightness(1.2)",
    },
    [theme.breakpoints.down("sm")]: {
      height: "350px",
      width: "100%",
    },
    marginBottom: "5px",
    height: "150px !important",
  },
  categoryIcon: {
    width: "120px",
    height: "110px",
    background: "white",
    borderRadius: "14px",
    overflow: "hidden",
    cursor: "pointer",
    transition: "0.4s",
    boxSizing: "border-box",
    border: ` 2px solid white`,
    transitionTimingFunction: "cubic-bezier(0.165, 0.84, 0.44, 1.0)",
    "&:hover": {
      transform: "scale(0.9)",
      border: ` 2px solid ${QuikTaskMainColor}`,
    },
  },
  buttonPrimaryLight: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    textAlign: "center",
    borderRadius: "48px",
    padding: "10px 18px",
    cursor: "pointer",
    transition: "0.3s",
    height: "50px",
    background: "rgba(255, 255, 255,0.6)",
    border: "1.5px solid rgba(108, 82, 255,0.2)",
    "&:hover": {
      background: "rgba(255, 255, 255,1)",
    },
  },
}));

export default function QTAllCategoriesSection() {
  const classes = useStyles();
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("sm"));
  const TheMainColor = isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main;

  return (
    <div
      style={{
        background: "white",
        paddingTop: "40px",
        // marginTop:"100px",
        paddingBottom: "100px",
        overflowX: "auto",
        padding: "20px",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            margin: "50px",
            alignSelf: "center",
            marginBottom: "10px",
          }}
        >
          <Text variant="h2" black>
            What’s your next task?
          </Text>
        </Grid>
        <Grid item xs={12} style={{textAlign: "center", display: "flex", justifyContent: "center"}}>
          <div style={{height: "12px"}} />
          <Text variant="body1" style={{maxWidth: "350px", margin: "0 auto "}}>
            Get help with home repairs, heavy lifting, gardening & more.
          </Text>
        </Grid>

        <div style={{height: "48px"}} />
        {xsDown ? (
          <div style={{overflowX: "auto"}}>
            <Grid container spacing={6} style={{flexWrap: "nowrap"}}>
              {categories().map((category) => (
                <Grid item xs={12} key={category}>
                  <InlineLink to={`/services/${getCategoryLink(category)}`}>
                    <div className={classes.categoryCardContainer}>
                      <div className={classes.categoryIcon}>
                        <img
                          loading="lazy"
                          style={{
                            padding: "18px",
                            width: "120px",
                            height: "110px",
                            boxSizing: "border-box",
                          }}
                          src={getCategoryImage(category).toString()}
                          alt={category}
                        />
                      </div>
                      <Text
                        variant="caption"
                        medium
                        style={{
                          textTransform: "capitalize",
                          marginTop: "8px",
                        }}
                      >
                        {category}
                      </Text>
                    </div>
                  </InlineLink>
                </Grid>
              ))}
            </Grid>
          </div>
        ) : (
          <Grid container spacing={0}>
            {categories().map((category) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={2}
                key={category}
                style={{marginTop: "20px", border: "red"}}
              >
                <InlineLink to={`/services/${getCategoryLink(category)}`}>
                  <div className={classes.categoryCardContainer}>
                    <div className={classes.categoryIcon}>
                      <img
                        loading="lazy"
                        style={{
                          padding: "18px",
                          width: "120px",
                          height: "110px",
                          boxSizing: "border-box",
                        }}
                        src={getCategoryImage(category).toString()}
                        alt={category}
                      />
                    </div>
                    <Text
                      variant="caption"
                      medium
                      style={{textTransform: "capitalize", marginTop: "8px", textAlign: "center"}}
                    >
                      {category}
                    </Text>
                  </div>
                </InlineLink>
              </Grid>
            ))}
          </Grid>
        )}
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "30px",
            height: "50px",
          }}
        >
          <Link to="/services">
            <div
              className={classes.buttonPrimaryLight}
              style={{
                border: isQuickTaskProject
                  ? `2px solid ${TheMainColor}`
                  : "1.5px solid rgba(108, 82, 255, 0.2)",
              }}
            >
              <Text
                variant="body2"
                bold
                style={{
                  margin: "0 8px",
                  color: TheMainColor,
                }}
              >
                View All
              </Text>
            </div>
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}
