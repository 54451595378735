import * as React from "react";
import {Container, Grid} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Text from "../data-display/text";
import makeStyles from "@material-ui/core/styles/makeStyles";
import InlineLink from "../data-display/inline-link";
import {getCategoryLink} from "../../content/category-icons";
import {Link} from "gatsby";
import {categories} from "../../utils/category-icons";
import {getCategoryImage} from "../../utils/category-icons";
import {environment} from "../../environments/quickTaskEnvironemt";
import {isQuickTaskProject} from "../../utils/constant.urls";
const QuikTaskMainColor = environment.mainColor;

const useStyles = makeStyles(() => ({
  categoryCardContainer: {
    position: "relative",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    cursor: "pointer",
    borderRadius: "8px",
    transition: "0.5s",
    "&:hover": {
      filter: "brightness(1.2)",
    },
  },
  categoryCardContainerLast: {
    position: "relative",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    cursor: "pointer",
    height: "350px",
    borderRadius: "8px",
    transition: "0.5s",
    "&:hover": {
      filter: "brightness(0.95)",
    },
  },
  categoryIcon: {
    width: "120px",
    height: "110px",
    background: "white",
    borderRadius: "14px",
    border: "2px solid rgba(180,180,200,0.2)",
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
    transition: "0.4s",
    boxSizing: "border-box",
    transitionTimingFunction: "cubic-bezier(0.165, 0.84, 0.44, 1.0)",
    "&:hover": {
      transform: "scale(0.9)",
    },
  },
  buttonPrimaryLight: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    textAlign: "center",
    borderRadius: "48px",
    padding: "10px 18px",
    cursor: "pointer",
    transition: "0.3s",
    background: "rgba(255, 255, 255,0.6)",
    border: "1.5px solid rgba(108, 82, 255,0.2)",
    "&:hover": {
      background: "rgba(255, 255, 255,1)",
    },
  },
}));
export default function AllCategoriesSection() {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const TheMainColor = isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main;

  return (
    <div>
      {" "}
      <div style={{background: "white", paddingTop: "40px", paddingBottom: "100px"}}>
        <Container maxWidth={"lg"}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
              <Text variant={"h2"} black>
                {" "}
                What’s your next task?{" "}
              </Text>
              <div style={{height: "12px"}} />
              <Text
                variant={"body1"}
                style={{maxWidth: "350px", margin: "0 auto", textAlign: "center"}}
              >
                Get help with home repairs, heavy lifting, yard work & more.
              </Text>
            </div>
            <div style={{height: "48px"}} />
            {!xsDown && (
              <Grid container spacing={4}>
                {categories().map((c) => {
                  return (
                    <Grid
                      item
                      xs={3}
                      sm={4}
                      md={4}
                      lg={2}
                      key={c}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <InlineLink to={"/services/" + getCategoryLink(c)}>
                        <div
                          className={classes.categoryCardContainer}
                          style={{
                            margin: xsDown ? "0 16px" : "",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className={classes.categoryIcon}
                            style={{
                              border: "2px solid white",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              loading={"lazy"}
                              style={{
                                padding: "18px",
                                height: isQuickTaskProject ? "60%" : "auto",
                                width: isQuickTaskProject ? "60%" : "100%",
                                boxSizing: "border-box",
                              }}
                              src={getCategoryImage(c).toString()}
                              alt={c}
                            />
                          </div>
                          <Text
                            variant={"caption"}
                            selectable={false}
                            medium
                            style={{textTransform: "capitalize", marginTop: "8px"}}
                          >
                            {c}
                          </Text>
                        </div>
                      </InlineLink>
                    </Grid>
                  );
                })}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginRight: "20px",
                    right: 0,
                    left: 0,
                    marginTop: "30px",
                  }}
                >
                  <div
                    style={{
                      background: "linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0.25))",
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      left: 0,
                      right: 0,
                    }}
                  />
                  <InlineLink variant={"primary"} to={"/services"} style={{color: TheMainColor}}>
                    <div
                      className={classes.buttonPrimaryLight}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "200px",
                        marginLeft: smDown ? "55px" : "30px",
                      }}
                    >
                      <Text selectable={false} variant={"body2"} bold style={{color: TheMainColor}}>
                        View All
                      </Text>
                    </div>
                  </InlineLink>
                </Grid>
              </Grid>
            )}

            {xsDown && (
              <div style={{display: "flex", alignItems: "center", width: "100%"}}>
                <div style={{overflowX: "auto", WebkitOverflowScrolling: "touch", width: "100%"}}>
                  <div style={{display: "-webkit-box", alignItems: "center"}}>
                    {categories().map((c) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={4}
                          lg={2}
                          key={c}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <InlineLink to={"/services/" + getCategoryLink(c)}>
                            <div
                              className={classes.categoryCardContainer}
                              style={{
                                margin: xsDown ? "0 16px" : "",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div
                                className={classes.categoryIcon}
                                style={{
                                  border: "2px solid white",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  loading={"lazy"}
                                  style={{
                                    padding: "18px",
                                    width: isQuickTaskProject ? "60%" : "100%",
                                    height: isQuickTaskProject ? "60%" : "auto",
                                    boxSizing: "border-box",
                                  }}
                                  src={getCategoryImage(c).toString()}
                                  alt={c}
                                />
                              </div>
                              <Text
                                variant={"caption"}
                                selectable={false}
                                medium
                                style={{textTransform: "capitalize", marginTop: "8px"}}
                              >
                                {c}
                              </Text>
                            </div>
                          </InlineLink>
                        </Grid>
                      );
                    })}
                    <Link
                      to={"/services"}
                      style={{marginRight: "16px", display: "flex", width: "70vw"}}
                    >
                      <div
                        className={classes.categoryCardContainerLast}
                        style={{width: "100%", justifyContent: "center", alignItems: "center"}}
                      >
                        <div
                          style={{
                            background:
                              "linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0.25))",
                            position: "absolute",
                            left: 0,
                            right: 0,
                            width: "100%",
                            height: "100%",
                          }}
                        />
                        <InlineLink
                          variant={"primary"}
                          to={"/services"}
                          style={{
                            color: TheMainColor,
                          }}
                        >
                          <div className={classes.buttonPrimaryLight}>
                            <Text
                              selectable={false}
                              variant={"body2"}
                              bold
                              style={{
                                margin: "0 8px",
                                color: TheMainColor,
                              }}
                            >
                              View All
                            </Text>
                          </div>
                        </InlineLink>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>
    </div>
  );
}
