import * as React from "react";
import {useTheme} from "@mui/system";
import {useMediaQuery} from "@mui/material";
import Text from "../data-display/text";
import HeroImg from "../../images/hero_home.webp";
import Wave from "../../images/wave_1.webp";
import Wave2 from "../../images/wave_2.png";
import Gradient1 from "../../images/hero_gradients.svg";
import Gradient2 from "../../images/hero_gradients_2.svg";
import SecondaryButton from "../buttons/secondary-button";
import InlineLink from "../data-display/inline-link";
import PlayStore from "../../images/google_playstore.svg";
import AppStore from "../../images/apple-appstore.svg";
import {isIOS} from "react-device-detect";
import {environment} from "../../environments/quickTaskEnvironemt";
import QuiklandingPage from "../../quickTaskAssets/peopleImgs/mannobackground.png";
import {isQuickTaskProject, title, GooglePlayLink, appStoreLink} from "../../utils/constant.urls";

export default function LandingPageSection() {
  const theme = useTheme();
  const Hero = isQuickTaskProject ? QuiklandingPage : HeroImg;
  const QuickTaskMainColor = environment.mainColor;
  const btn_background = isQuickTaskProject ? "white" : theme.palette.primary.main;
  const btn_color = isQuickTaskProject ? QuickTaskMainColor : "white";
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div
      style={{
        background: isQuickTaskProject ? QuickTaskMainColor : "inherit",
      }}
    >
      <div
        style={{
          minHeight: isIOS ? "-webkit-fill-available" : "100vh",
          width: "100%",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          background: isQuickTaskProject ? QuickTaskMainColor : "inherit",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: "0",
            bottom: "0",
            width: "80%",
            height: "100%",
            background: `url(${Wave}) bottom left / contain no-repeat`,
          }}
        />
        <div
          style={{
            position: "absolute",
            right: "0%",
            bottom: "0",
            width: "60%",
            height: "100%",
            opacity: "0.5",
            background: `url(${Gradient1}) 150% bottom / contain no-repeat`,
          }}
        />
        <div
          style={{
            position: "absolute",
            left: "0",
            top: "-10%",
            width: "50%",
            height: "100%",
            opacity: "0.2",
            background: `url(${Gradient2}) top left / contain no-repeat`,
          }}
        />
        <div
          style={{
            background: `url(${Gradient2}) top left / contain no-repeat`,
          }}
        />
        <div
          style={{
            position: "absolute",
            bottom: "0",
            left: xsDown ? 0 : undefined,
            right: xsDown ? undefined : "0",
            width: isQuickTaskProject ? (xsDown ? "100%" : "50%") : "100%",
            height: isQuickTaskProject ? (xsDown ? "90%" : "90%") : xsDown ? "40%" : "50%",
            background: isQuickTaskProject
              ? xsDown
                ? `url(${Hero}) -148px bottom right / cover no-repeat`
                : `url(${Hero}) bottom right / cover no-repeat`
              : xsDown
              ? `url(${Hero}) -148px center / cover`
              : `url(${Hero}) 200% bottom / contain no-repeat`,
            backgroundSize: "contain", // Adjust background size to contain the entire image
          }}
        />

        <div
          style={{
            position: "absolute",
            left: "0",
            bottom: isQuickTaskProject ? "-18px" : "-18px",
            width: isQuickTaskProject ? "110%" : "110%",
            height: "100%",
            background: `url(${Wave2}) bottom left / contain no-repeat`,
            zIndex: "100",
          }}
        />

        {isQuickTaskProject ? (
          <>
            <Text
              variant={"h1"}
              style={{
                textAlign: "center",
                color: "white",
                marginTop: "80px",
              }}
            >
              <span style={{fontSize: "55px"}}>QuickTask</span>
            </Text>
            <Text
              style={{
                //marginBottom: "32px",
                maxWidth: "600px",
                color: "white",
                textAlign: "center",
              }}
              variant={"h4"}
            >
              TASKS MADE SIMPLE
            </Text>
          </>
        ) : (
          <>
            <Text variant={"h1"} style={{textAlign: "center", color: "black", marginTop: "50px"}}>
              Let's get more done.
            </Text>
            <Text
              style={{marginBottom: "32px", maxWidth: "600px", textAlign: "center"}}
              variant={"h4"}
            >
              Connect with trusted Taskers with the skills you need.
            </Text>
          </>
        )}
        <InlineLink to="/tasks">
          <div style={{width: "200px"}}>
            <SecondaryButton
              background={btn_background}
              textColor={btn_color}
              onClick={() => window.location.assign("/tasks")}
            >
              Get Started
            </SecondaryButton>
          </div>
        </InlineLink>
        <div style={{height: "48px"}} />
        <div style={{display: "flex", position: "relative"}}>
          <InlineLink to={GooglePlayLink}>
            <div style={{width: "200px"}}>
              <SecondaryButton onClick={() => window.location.assign(GooglePlayLink)}>
              <img loading="lazy" src={PlayStore} alt={`Download ${title} `} />
              </SecondaryButton>
            </div>
          </InlineLink>
          <div style={{width: "18px"}} />
          <InlineLink to={appStoreLink}>
            <div style={{width: "150px"}}>
              <SecondaryButton onClick={() => window.location.assign(appStoreLink)}>
              <img loading="lazy" src={AppStore} alt={`Download ${title} `} />
              </SecondaryButton>
            </div>
          </InlineLink>
        </div>
        <div style={{height: "30vh"}} />
      </div>
    </div>
  );
}
