import React from "react";
import BlogsCard from "../blogs-card";
import {useTheme} from "@mui/system";
import {useMediaQuery} from "@mui/material";
import {graphql, useStaticQuery} from "gatsby";
import BlogsModel from "../blogs-model";
import Text from "../../data-display/text";
import InlineLink from "../../../components/data-display/inline-link";
import {environment} from "../../../environments/quickTaskEnvironemt";
import { title,isQuickTaskProject } from "../../../utils/constant.urls";
const QuikTaskMainColor = environment.mainColor;
const styles ={
  buttonPrimaryLight: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    textAlign: "center",
    borderRadius: "48px",
    padding: "10px 18px",
    cursor: "pointer",
    transition: "0.3s",
    background: "rgba(255, 255, 255,0.6)",
    border: "1.5px solid rgba(108, 82, 255,0.2)",
    "&:hover": {
      background: "rgba(255, 255, 255,1)",
    },
  },
}

const FeaturedBlogs = () => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("sm"));

  const FeaturedBlogs = useStaticQuery(graphql`
    {
      featured: allBlogs {
        edges {
          node {
            author
            avatar
            content
            subTitle
            updateAt
            image
            slug
            title
          }
        }
      }
    }
  `);
  const blogs: BlogsModel[] = FeaturedBlogs?.featured?.edges
  ?.map((el) => new BlogsModel(el.node))
  .filter(blog => blog.title && blog.author && blog.slug) ?? [];
  return (
    <>
      <div style={{height: xsDown ? "24px" : "64px"}} data-scroll={true} />
      {xsDown && (
        <div
          style={{
            scrollSnapType: "x mandatory",
            display: "flex",
            WebkitOverflowScrolling: "touch",
            overflowX: "scroll",
          }}
        >
          <div style={{display: "-webkit-box", alignItems: "center"}}>
            {blogs
              .filter((blog) => blog.title && blog.author && blog.slug) // Add more conditions if needed
              .slice(0, 3)
              .map((p, index) => {
                return (
                  <BlogsCard
                    inView={true}
                    key={index}
                    style={{
                      marginRight: "24px",
                      marginBottom: "0",
                      scrollSnapAlign: "start",
                    }}
                    smallMargin
                    title={p.title}
                    subtitle={p.subTitle}
                    image={p.image}
                    author={p.author}
                    updateAt={p.updateAt}
                    to={`/blogs/${p.slug}/`}
                  />
                );
              })}
          </div>
        </div>
      )}
      {!xsDown && (
        <div style={{display: "flex", padding: " 10px 150px"}}>
          {blogs
            .filter((b) => b?.project === "quicktask")
            .slice(0, 3)
            .map((p, index) => {
              return (
                <React.Fragment key={index}>
                  <BlogsCard
                    inView={true}
                    key={index}
                    style={{
                      marginRight: "24px",
                      marginBottom: "0",
                      scrollSnapAlign: "start",
                      padding: "0px",
                      margin: "0px",
                    }}
                    smallMargin
                    title={p.title}
                    subtitle={p.subTitle}
                    image={p.image}
                    author={p.author}
                    updateAt={p.updateAt}
                    to={`/blogs/${p.slug}/`}
                  />
                  {index !== 2 && <div style={{width: "32px"}} />}
                </React.Fragment>
              );
            })}
        </div>
      )}
      <div style={{height: xsDown ? "0px" : "64px"}} data-scroll={true} />
      <div
        data-scroll={true}
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: xsDown ? "column" : "row",
        }}
      >
        <InlineLink
          variant={"primary"}
          to={"/blogs"}
          style={{color: isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main}}
        >
          <div
            style={{
              ...(styles.buttonPrimaryLight as React.CSSProperties),
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "200px",
              border: isQuickTaskProject
                ? `solid 2px ${QuikTaskMainColor}`
                : "1.5px solid rgba(108, 82, 255,0.2)",
            }}
          >
            <Text
              selectable={false}
              variant={"body2"}
              bold
              style={{
                color: isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main,
              }}
            >
              View All Blogs
            </Text>
          </div>
        </InlineLink>
      </div>
      <div style={{height: xsDown ? "0px" : "64px"}} data-scroll={true} />
    </>
  );
};

export default FeaturedBlogs;
