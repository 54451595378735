import React from "react";
import Text from "../../data-display/text"
import StarRating from "../../../../../src/components/reviews-ratings/star-rating";
import Avatar from "../../avatar/avatar";
import {useTheme} from "@mui/system";
import {useMediaQuery} from "@mui/material";

const ReviewCard = ({review}: {review:any}) => {

    const theme = useTheme();
    const xsDown = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <div style={{ background: xsDown ? "" : "rgb(255,255,255)", boxShadow: xsDown ? "" : "0 8px 46px -14px rgba(0,0,0,0.07)", borderRadius: xsDown ? "" : '14px', borderBottom: xsDown ? "1.5px solid rgb(220,220,220)" : "", padding: "18px 20px" }}>
            <div style={{ display: "flex", alignItems: "flex-start" }}>
                <Avatar name={review.name} profilePicture={review.profilePicture} size={32}/>
                <div style={{ marginLeft: "8px", lineHeight: "1.0" }}>
                    <Text variant={"body2"} medium >
                        {review?.name}
                    </Text>
                    <Text variant={"caption"} regular style={{ opacity: "0.8", lineHeight: "1.1" }}>
                        {review?.taskTitle}
                    </Text>
                </div>
                <div style={{ marginLeft: "auto" }}/>
                <StarRating totalAverage={5} size={18}/>
            </div>
            <div style={{ height: "12px" }}/>
            <div style={{ height: "4px" }}/>
            <Text variant={"body2"} regular>
                "{review?.text.trim()}"
            </Text>
        </div>
    )
}

export default ReviewCard;