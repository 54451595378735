import * as React from "react";
import {Container, Grid} from "@mui/material";
import {useTheme} from "@mui/system";
import {useMediaQuery} from "@mui/material";
import Text from "../../components/data-display/text";
import ReviewCard from "../../components/home/reviews/review-card";
import review from "../../components/home/reviews/reviews.json";
export default function AllReviewSection() {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      {" "}
      <div
        style={{
          position: "relative",
          zIndex: 1,
          padding: "48px 0",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          display: "flex",
        }}
      >
        <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
          <Text variant={"h2"} black>
            Reviews From Our Customers
          </Text>
          <div style={{height: "48px"}} />
        </div>
        <Container maxWidth={"lg"}>
          <Grid container spacing={xsDown ? 0 : 5}>
            {(!xsDown ? review : review.slice(0, 3)).map((r) => {
              return (
                <Grid item key={r.name} xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ReviewCard review={r} />
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </div>
    </div>
  );
}
