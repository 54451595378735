import React from "react";
import Text from "../../components/data-display/text";
import FeaturedBlogs from "../../components/blogs/homePageView/featured-blogs";
export default function AllBlogsSection() {
  return (
    <div
      style={{
        position: "relative",
        zIndex: 1,
        padding: "48px 0",
        background: "white",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        display: "flex",
      }}
    >
      <Text variant={"h2"} black>
        Our Blogs
      </Text>
      <FeaturedBlogs />
    </div>
  );
}
